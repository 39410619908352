import Vue from 'vue'

// var objects = [
//     "TextField",
//     "TimePicker",
//     "DatePicker",
//     "Select",
//     "Carousel",
//     "Address",
// ];

Vue.component('Likes', () => import(`../components/Likes.vue`))
Vue.component('Dock', () => import(`../components/Dock.vue`))
Vue.component('Menu', () => import(`../components/Menu.vue`))
Vue.component('BaseRow', () => import(`../components/BaseRow.vue`))
Vue.component('Instructor', () => import(`../components/Instructor.vue`))
Vue.component('SmallEventCard', () => import(`../components/cards/SmallEventCard.vue`))
Vue.component('EventCard', () => import(`./cards/EventCard.vue`))
Vue.component('UserCard', () => import(`./cards/UserCard.vue`))
Vue.component('SmallAreaCard', () => import(`../components/cards/SmallAreaCard.vue`))
Vue.component('SmallButton', () => import(`../components/SmallButton.vue`))
Vue.component('ImageChanger', () => import(`../components/ImageChanger.vue`))
Vue.component('Button', () => import(`../components/Button.vue`))
Vue.component('TextField', () => import(`../components/TextField.vue`))
Vue.component('CollapseButton', () => import(`../components/CollapseButton.vue`))
Vue.component('Search', () => import(`../components/Search.vue`))
Vue.component('NotificationButton', () => import(`../components/NotificationButton.vue`))
Vue.component('PlaceCardMini', () => import(`../components/parts/PlaceCardMini.vue`))
Vue.component('ActionButton', () => import(`../components/ActionButton.vue`))
Vue.component('ToggleButton', () => import(`../components/ToggleButton.vue`))
Vue.component('BaseCard', () => import(`../components/cards/BaseCard.vue`))
Vue.component('IconButton', () => import(`../components/IconButton.vue`))
Vue.component('JoinButton', () => import(`../components/parts/JoinButton.vue`))
Vue.component('SmBtn', () => import(`../components/SmBtn.vue`))
Vue.component('SocialIcon', () => import(`../components/parts/SocialIcon.vue`))
Vue.component('PlaceCard', () => import(`../components/PlaceCard.vue`))
// objects.forEach(element => {
//     var name = element.includes("/") ? element.split("/")[element.split("/").length - 1] : element;
//     Vue.component(name, () => import(`../components/${element}.vue`))
// });