import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {
    status: {
        loggedIn: true
    },
    user
} : {
    status: {
        loggedIn: false
    },
    user: null
};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({
            commit
        }, data) {
            let user = data.user
            return AuthService.login(user, data.code).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        login2auth({
            commit
        }, user) {
            localStorage.setItem('user', JSON.stringify(user));
            commit('loginSuccess', user);
        },
        loginDiscord({
            commit
        }, data) {
            return AuthService.discordLogin(data).then(
                user => {
                    localStorage.setItem('user', JSON.stringify(user.data));
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user.data);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        loginVK({
            commit
        }, data) {

            return AuthService.vkLogin(data).then(
                user => {

                    localStorage.setItem('user', JSON.stringify(user.data));
                    commit('loginSuccess', user.data);
                    return Promise.resolve(user.data);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        connectDiscord({
            commit
        }, data) {
            return AuthService.discordConnect(data).then(
                user => {
                    let u = JSON.parse(localStorage.getItem('user'))
                    u.integrations.discord = user.data
                    localStorage.setItem('user', JSON.stringify(u));
                    commit('DloginSuccess', user.data);
                    return Promise.resolve(user.data);
                },
                error => {
                    console.log(error);
                    commit('DloginFail');
                    return Promise.reject(error);
                }
            );
        },
        connectVK({
            commit
        }, data) {
            return AuthService.vkConnect(data).then(
                user => {
                    let u = JSON.parse(localStorage.getItem('user'))
                    u.integrations.vk = user.data
                    localStorage.setItem('user', JSON.stringify(u));
                    commit('VKloginSuccess', user.data);
                    return Promise.resolve(user.data);
                },
                error => {
                    console.log(error);
                    commit('VKloginFail');
                    return Promise.reject(error);
                }
            );
        },
        refresh({
            commit
        }, token, refresh) {

            return AuthService.refresh(token, refresh).then(
                res => {

                    var user = res.data.user
                    user.accessToken = res.data.token
                    user.refreshToken = res.data.refresh
                    //console.log(res.data.token);
                    user.maxLevel = Math.max.apply(Math, user.roles.map(function (o) {
                        return o.level;
                    }));

                    localStorage.setItem('user', JSON.stringify(user));
                    commit('loginSuccess', user);
                    return Promise.resolve(user);

                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({
            commit
        }) {
            //console.log(commit);
            AuthService.logout();
            commit('logout');
        },
        register({
            commit
        }, data) {
            let user = data.user
            // console.log(data);
            return AuthService.register(user, data.code).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshToken({
            commit
        }, accessToken) {
            commit('refreshToken', accessToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        refreshToken(state, accessToken) {
            state.status.loggedIn = true;
            state.user = {
                ...state.user,
                accessToken: accessToken
            };
        },
        DloginSuccess(state) {
            state.status.discordOk = true //НЕИСПОЛЬЗУЕМОЕ
        },
        DloginFail(state) {
            state.status.discordOkA = false //НЕИСПОЛЬЗУЕМОЕ
        },
        VKloginSuccess(state) {
            state.status.VKOkA = true //НЕИСПОЛЬЗУЕМОЕ
        },
        VKloginFail(state) {
            state.status.VKOkA = false //НЕИСПОЛЬЗУЕМОЕ
        }
    }
};