import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
  auth
} from './auth.module';

export default new Vuex.Store({
  state: {
    dockState: undefined,
    // FullEventCard: undefined,
    // CoachListCard: undefined,
    // CoachCard: undefined,
    // AreaCard: undefined,
    // CreatingEventCard: undefined,
    // CreatingSponsorCard: undefined,
    // SponsorCard: undefined,
    // ProfileEdit: undefined,
    // ProfileCard: undefined,
    // CreatingPlaceCard: undefined,
    // ConfirmCard: undefined,
    // EditingData: undefined,
    // FiltersCard: undefined,
    position: undefined,
    me: undefined,
    UserCard: false,
    EventCard: false,
    BaseCard: false,
    isMobile: false,
    HelpPWA: false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
  }
})


