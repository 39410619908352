import axiosInstance from "./api";
import TokenService from "./token.service";
import AuthService from "./auth.service";

import router from '../router'
const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            //console.log(config.url);
            const token = TokenService.getLocalAccessToken();
            if (token) {
                //config.headers['content-type'] = "multipart/form-data"
                // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                config.headers["x-access-token"] = token; // for Node.js Express back-end
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            //console.log(res);
            //console.log(TokenService.getUser());
            //console.log(res.config.url);
            if (res.config.url != '/auth/refresh' && res.config.url != '/auth/refreshtoken') {

                AuthService.refresh(TokenService.getLocalAccessToken(), TokenService.getLocalRefreshToken())
                    .then(res => {
                        if (!res?.data) return;
                        //console.log(res.data.user);
                        TokenService.updateUser(res.data.user)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            return res;
        },
        async (err) => {
            console.log(err.response?.status, 'status --')
            if (err.response?.status == 403) {
                // if (router.currentRoute.path == '/503') return
                return  router.push('/login')
                // console.log('ebobo')
                
            }

            const originalConfig = err.config;
            console.log(err)
            //console.log('Update...');
            if (originalConfig.url !== "/auth/signin" && err.response) {
                // Access Token was expired
                console.log('TOKEN EXPIRED')
                if (err.response?.status === 419 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axiosInstance.post("/auth/refreshtoken", {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });
                        //console.log(rs.data);
                        const {
                            accessToken
                        } = rs.data;

                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);
                        //console.log('Updated');
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        TokenService.removeUser()
                        router.push("/sign")
                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;