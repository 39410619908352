class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    //console.log(user);
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    //console.log(localStorage.getItem("user").refreshToken);
    return JSON.parse(localStorage.getItem("user"));
  }

  updateUser(data) {
    let user = JSON.parse(localStorage.getItem("user"));
    //console.log(data.accessToken);
    //console.log(user.accessToken);
    data.accessToken = user.accessToken
    data.refreshToken = user.refreshToken
    //console.log(data.accessToken);
    localStorage.setItem("user", JSON.stringify(data));
  }

  setUser(user) {
    //console.log(JSON.stringify(user));
    //console.log(user.refreshToken);
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }
}

export default new TokenService();