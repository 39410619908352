import api from "./api";
import TokenService from "./token.service";

class AuthService {

    login({
        username,
        password
    }, code) {
        //console.log(code);
        return api
            .post("/auth/signin", {
                username,
                password,
                code
            })
            .then((response) => {
                if (response.data.accessToken) {
                    TokenService.setUser(response.data);
                }
                //console.log(response.data.refreshToken);
                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }

    register({
        username,
        email,
        password
    }, code) {
        return api.post("/auth/signup", {
            username,
            email,
            password,
            code
        });
    }

    refresh(token, refresh) {
        //console.log(refresh);
        return api.post('/auth/refresh', {
            token: token,
            refresh: refresh
        }).catch(err => {
            console.log(err)
        })
    }

    getTestInfo() {
        return api.get('/auth/getTestInfo')
    }

}

export default new AuthService();