<template>
  <v-app>
    <!-- ширма -->
    <!-- <span style="position: relative; z-index: 100; top: 0; height: 0; font-size: 10px">v0.5.9 04.07.2023</span> -->
    <!-- {{ $store.state.isMobile }} -->
    <div
      :class="{ hidden: !isSomeOneModalIsOpen }"
      @click="CloseAllModals(false)"
      class="cover2"
    ></div>

    <div v-if="$store.state.UserCard" class="FullScreenCard">
      <UserCard
        :class="{ fullWidth: $store.state.isMobile }"
        :user="$store.state.UserCard"
        @close="$store.state.UserCard = undefined"
      />
    </div>

    <div
      v-if="$store.state.BaseCard"
      class="FullScreenCard"
      :style="{ display: !$store.state.UserCard ? 'flex' : 'none' }"
    >
      <BaseCard
        :class="{ fullWidth: $store.state.isMobile }"
        :data="$store.state.BaseCard"
        @close="CloseAllModals(true)"
      />
    </div>

    <div v-if="$store.state.EventCard" class="FullScreenCard">
      <EventCard
        :class="{ fullWidth: $store.state.isMobile }"
        :data="$store.state.EventCard"
        @close="CloseAllModals(true)"
      />
    </div>

    <div
      v-if="$store.state.HelpPWA"
      class="FullScreenCard"
      style="
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: rgba(1, 1, 1, 0.5);
        backdrop-filter: blur(2px);
      "
    >
      <div
        style="
          display: flex;
          width: 280px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background: #f2f2f2;
        "
      >
        <span
          style="
            color: var(--, #3d3d3d);
            text-align: center;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.05px;
          "
          >Как сохранить сайт в виде мобильного приложения себе на
          телефон?</span
        >
      </div>
      <div
        style="
          display: flex;
          width: 280px;
          padding: 5px;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 6px;
          background: #f2f2f2;
        "
      >
        <span
          style="
            color: var(--, #3d3d3d);
            text-align: center;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.05px;
            width: 100%;
            padding: 4px 0;
          "
          >Инструкция:</span
        >
        <div style="display: flex; width: 100%">
          <div
            @click="helpType = 'android'"
            :style="{ background: helpType == 'android' ? '#fff' : '#f2f2f2' }"
            style="
              cursor: pointer;
              display: flex;
              height: 25px;
              padding: var(--desctop, 0px) 10px;
              justify-content: center;
              align-items: center;
              gap: var(--desctop, 0px);
              flex: 1 0 0;
              border-radius: 3px 3px var(--desctop, 0px) var(--desctop, 0px);
              overflow: hidden;
              color: var(--1, #000);
              text-align: center;
              text-overflow: ellipsis;
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.05px;
            "
          >
            Android
          </div>
          <div
            @click="helpType = 'ios'"
            :style="{ background: helpType == 'ios' ? '#fff' : '#f2f2f2' }"
            style="
              cursor: pointer;
              display: flex;
              height: 25px;
              padding: var(--desctop, 0px) 10px;
              justify-content: center;
              align-items: center;
              gap: var(--desctop, 0px);
              flex: 1 0 0;
              border-radius: 3px 3px var(--desctop, 0px) var(--desctop, 0px);
              overflow: hidden;
              color: var(--1, #000);
              text-align: center;
              text-overflow: ellipsis;
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.05px;
            "
          >
            iOS
          </div>
        </div>

        <div
          style="
            display: flex;
            padding: 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;

            background: var(--3, #fff);
          "
          :style="{
            'border-radius':
              helpType == 'ios' ? '3px 0px 3px 3px' : '0px 3px 3px 3px',
          }"
        >
          <div style="width: 100%">
            <div v-if="helpType == 'ios'" :initial-slide="0" class="">
              <div
                v-for="item in helpData.ios"
                :key="helpData.ios.indexOf(item)"
                class="menu"
              >
                <div
                  v-if="helpStep == helpData.ios.indexOf(item)"
                  style="
                    display: flex;
                    padding: var(--desctop, 0px);
                    flex-direction: column;
                    align-items: center;
                    gap: 5px;
                    text-align: center;
                    width: 100%;
                  "
                >
                  <span
                    style="
                      overflow: hidden;
                      color: var(--1, #000);
                      text-align: center;
                      text-overflow: ellipsis;
                      font-family: Inter;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      letter-spacing: 0.05px;
                    "
                    >Шаг {{ helpData.ios.indexOf(item) + 1 }}</span
                  >
                  <span
                    style="
                      color: var(--1, #000);
                      text-align: center;
                      font-family: Inter;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.05px;
                    "
                  >
                    {{ item.text }}
                  </span>
                </div>
                <div class="mt-1" v-if="helpStep == helpData.ios.indexOf(item)">
                  <img
                    style="border-radius: 6px; overflow: hidden"
                    :src="
                      require(`./assets/ios-${
                        helpData.ios.indexOf(item) + 1
                      }.png`)
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div v-if="helpType == 'android'" :initial-slide="0" class="">
              <div
                v-for="item in helpData.android"
                :key="helpData.android.indexOf(item)"
                class="menu"
              >
                <div
                  v-if="helpStep == helpData.android.indexOf(item)"
                  style="
                    display: flex;
                    padding: var(--desctop, 0px);
                    flex-direction: column;
                    align-items: center;
                    gap: 5px;
                    text-align: center;
                    width: 100%;
                  "
                >
                  <span
                    style="
                      overflow: hidden;
                      color: var(--1, #000);
                      text-align: center;
                      text-overflow: ellipsis;
                      font-family: Inter;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 700;
                      line-height: normal;
                      letter-spacing: 0.05px;
                    "
                    >Шаг {{ helpData.android.indexOf(item) + 1 }}</span
                  >
                  <span
                    style="
                      color: var(--1, #000);
                      text-align: center;
                      font-family: Inter;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.05px;
                    "
                  >
                    {{ item.text }}
                  </span>
                </div>
                <div
                  class="mt-1"
                  v-if="helpStep == helpData.android.indexOf(item)"
                >
                  <img
                    style="border-radius: 6px; overflow: hidden"
                    :src="
                      require(`./assets/ios-${
                        helpData.android.indexOf(item) + 1
                      }.png`)
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <Button v-if="helpStep < 3" block @click="helpStep = helpStep + 1">
              Далее
            </Button>
            <Button
              v-if="helpStep == 3"
              block
              @click="$store.state.HelpPWA = false"
            >
              Понятно
            </Button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="$store.state.EventCard" class="FullScreenCard">
      <BaseCard :data="$store.state.EventCard" @close="CloseAllModals(true)" />
    </div> -->

    <!-- <div v-if="$store.state.ConfirmCard" class="ConfirmCard">
      <ConfirmCard @close="CloseAllModals(true)" />
    </div> -->

    <!-- <div v-if="$store.state.SponsorCard && $store.state.FullEventCard?.sponsor" class="SponsorCard">
      <SponsorInfo @close="$store.state.SponsorCard = false" :data="$store.state.FullEventCard?.sponsor" />
    </div>

    
    <div v-if="$store.state.CoachListCard && !$store.state.CoachCard" class="CoachListCard">
      <CoachList :data="$store.state.FullEventCard" @close="$store.state.CoachListCard = false" />
    </div>

    
    <div v-if="$store.state.FullEventCard && !$store.state.SponsorCard && !$store.state.CoachListCard" class="FullEventCard">
      <EventCard @join="JoinIntoEvent" @close="$store.state.FullEventCard = undefined" @share="ShareEvent" @show_sponsor="ShowSponsor" @show_players="ShowPlayers" :data="$store.state.FullEventCard" />
    </div>

  
    <div v-if="$store.state.AreaCard" class="AreaCard">
      <ArenaCard @close="$store.state.AreaCard = undefined" @share="ShareEvent" :data="$store.state.AreaCard" />
    </div>

   
    <div v-if="$store.state.CoachCard && (!$store.state.FullEventCard || $store.state.CoachListCard)" class="CoachCard">
      <CoachCard :data="$store.state.CoachCard" @close="$store.state.CoachCard = false" />
    </div>

   
    <div v-if="$store.state.ProfileCard && !$store.state.ProfileEdit" class="ProfileCard">
      <CoachCard @edit="$store.state.ProfileEdit = JSON.parse(JSON.stringify($store.state.ProfileCard))" MyProfile :data="$store.state.ProfileCard" @close="$store.state.ProfileCard = false" />
    </div>

    
    <div v-if="$store.state.ProfileCard && $store.state.ProfileEdit" class="ProfileCard">
      <EditProfile
        @save="
          $store.state.ProfileCard = $store.state.ProfileEdit;
          $store.state.ProfileEdit = undefined;
        "
        :data="$store.state.ProfileEdit"
        @close="$store.state.ProfileEdit = undefined"
      />
    </div>
   
    <div v-if="$store.state.CreatingEventCard" class="CreatingEventCard">
      <CreateEvent @close="$store.state.CreatingEventCard = false" />
    </div>

    <div v-if="$store.state.FiltersCard" class="CreatingEventCard">
      <FiltersCard @close="$store.state.FiltersCard = false" />
    </div>

    <div v-if="$store.state.CreatingPlaceCard" class="CreatingPlaceCard">
      <CreatePlace @close="$store.state.CreatingPlaceCard = false" />
    </div>

    <Dock id="dock" v-if="ShowDock" /> -->
    <v-main
      :class="{
        isLogin: currentRoute == 'Login',
        grey: currentRoute == 'test',
      }"
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from "swiper/vue";
import { register } from "swiper/element/bundle";
register();

// Import Swiper styles
import "swiper/css";
export default {
  name: "App",
  // components: {
  //   Swiper,
  //   SwiperSlide,
  // },
  watch: {
    $route() {
      this.CloseAllModals();
    },
  },
  methods: {
    JoinIntoEvent() {
      alert("JoinIntoEvent");
    },
    ShareEvent() {
      alert("ShareEvent");
    },
    ShowSponsor() {
      // alert("ShowSponsor");
      this.$store.state.SponsorCard = true;
    },
    ShowPlayers() {
      this.$store.state.CoachListCard = true;
      // alert("ShowPlayers");
    },
    JoinIntoEvent() {
      alert("JoinIntoEvent");
    },
    CloseAllModals() {
      // if (arg === undefined) return;
      // if (this.$store.state.FullEventCard) return (this.$store.state.FullEventCard = undefined);
      // if (this.$store.state.CoachListCard) return (this.$store.state.CoachListCard = undefined);
      // if (this.$store.state.AreaCard) return (this.$store.state.AreaCard = undefined);
      // if (this.$store.state.SponsorCard) return (this.$store.state.SponsorCard = undefined);
      // if (this.$store.state.CoachCard) return (this.$store.state.CoachCard = undefined);
      // if (this.$store.state.CoachCard) return (this.$store.state.CoachCard = undefined);
      // if (!arg) {
      //   this.$store.state.ConfirmCard = true;
      // } else {
      this.$store.state.UserCard = undefined;
      this.$store.state.BaseCard = undefined;
      this.$store.state.EventCard = undefined;
      // this.$store.state.CoachListCard = undefined;
      // this.$store.state.CreatingEventCard = undefined;
      // this.$store.state.AreaCard = undefined;
      // this.$store.state.SponsorCard = undefined;
      // this.$store.state.ProfileCard = undefined;
      // this.$store.state.CoachCard = undefined;
      // this.$store.state.CreatingPlaceCard = undefined;
      // this.$store.state.ProfileEdit = undefined;
      // this.$store.state.ConfirmCard = undefined;
      // }
    },
  },
  created() {
    var user = JSON.parse(localStorage.getItem("user"));
    // console.log(user);
    this.$store.state.me = user;

    //     var prevScrollpos = window.pageYOffset;
    // window.onscroll = function() {
    //   var currentScrollPos = window.pageYOffset;
    //   var dock =   document.getElementById("dock");
    //   if (prevScrollpos < currentScrollPos && dock) {
    //     dock.style.bottom = "0";
    //   } else {
    //     dock.style.bottom = "-63px";
    //   }
    //   prevScrollpos = currentScrollPos;
    // }
  },
  mounted() {
    this.$store.state.isMobile = !!navigator.userAgent.match(
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/i
    );
    if (this.$store.state.isMobile)
      document.documentElement?.style.setProperty("--dockHeight", "32px");
    // this.$store.state.position = [55.175447, 61.394343];
    // console.log("check geo");

    // if ("geolocation" in navigator) {
    //   console.log("geo ok", navigator.geolocation);
    //   navigator.geolocation.getCurrentPosition(
    //     function (location) {
    //       console.log(location);
    //       console.log(location.coords.latitude);
    //       console.log(location.coords.longitude);
    //       console.log(location.coords.accuracy);

    //       this.$store.state.position = [location.coords.latitude, location.coords.longitude];
    //       // if (!location.coords) this.$store.state.position = [55.175447, 61.394343];
    //     },
    //     function (err) {
    //       console.log(err);
    //     }
    //   );
    // } else {
    //   this.$store.state.position = [55.175447, 61.394343];
    //   console.log("no geo");
    // }

    // if (this.$root.isMobile) {
    //   const page = document.getElementsByClassName("v-main")[0];
    //   let clientWidth = window.screen.availWidth - (window.screen.availWidth - window.innerWidth);
    //   let pageComputedWidth = 320;
    //   let resizeCoef = clientWidth / pageComputedWidth;
    //   document.documentElement?.style.setProperty("--scale", resizeCoef);
    //   document.documentElement?.style.setProperty("--dockHeight", "32px");
    //   // page.classList.add("scaler");
    //   // page.style.transformOrigin = `top `;
    //   // page.style.transform = `scale(${resizeCoef})`;

    //   setTimeout(() => {
    //     const dock = document.getElementsByClassName("dock")[0];
    //     if (!dock) return;
    //     dock.style.transformOrigin = `bottom`;
    //     dock.style.transform = `scale(${resizeCoef})`;
    //   }, 200);
    // }
  },
  computed: {
    isSomeOneModalIsOpen() {
      return (
        // this.$store.state.CreatingPlaceCard ||
        // this.$store.state.FullEventCard ||
        // this.$store.state.AreaCard ||
        // this.$store.state.CoachListCard ||
        // this.$store.state.CreatingEventCard ||
        // this.$store.state.CoachCard ||
        // this.$store.state.ProfileCard ||
        // this.$store.state.ProfileEdit ||
        this.$store.state.BaseCard ||
        this.$store.state.UserCard ||
        this.$store.state.EventCard
      );
    },
    ShowDock() {
      return [
        "Map",
        "Calendar",
        "Coachs",
        "Profile",
        "Events",
        "Coaches",
      ].includes(this.$route.name);
    },
    currentRoute() {
      return this.$route.name;
    },
    currentUser() {
      var user = JSON.parse(localStorage.getItem("user"));
      // console.log(user);
      this.$store.state.me = user;
      return user;
    },
  },
  data: () => ({
    helpStep: 0,
    helpType: "ios",
    helpData: {
      ios: [
        {
          text: "Удостоверьтесь, что перешли в браузер Safari, нажав на иконку компаса внизу экрана.",
          image: "img1",
        },
        {
          text: `В браузере на нижней панели нажмите на иконку “Поделиться”`,
          image: "img2",
        },
        {
          text: `Во всплывающем окне прокрутите до пункта “На экран «Домой»”`,
          image: "img3",
        },
        {
          text: `Подтвердите добавление значка на экран вашего телефона кнопкой “Добавить”`,
          image: "img4",
        },
      ],
      android: [
        {
          text: "Удостоверьтесь, что перешли в браузер Chrome...",
          image: "img1",
        },
        {
          text: `В браузере на нижней панели нажмите на иконку ”Настройки”`,
          image: "img2",
        },
        {
          text: `Во всплывающем окне прокрутите до пункта “Установить Приложение”`,
          image: "img3",
        },
        {
          text: `Подтвердите установку приложения кнопкой “Установить”`,
          image: "img4",
        },
      ],
    },
  }),
};
</script>

<style>
input:focus {
  outline-width: 0 !important;
}
.ProfileCard {
  z-index: 9 !important;
}
.container > *:not(.ya-map) {
  transform-origin: top;
  transform: scale(var(--scale));
}

:root {
  --dockHeight: 68px;
  --scale: 1;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #397524 #f2fdff;
}
::-webkit-scrollbar {
  display: none;
}
.fullWidthTop {
  width: calc(100% - 16px) !important;
  top: 8px !important;
}

.fullWidthBottom {
  width: calc(100% - 16px) !important;
  bottom: 8px !important;
}

.mobile {
  width: 320px !important;
  max-width: 320px !important;
  margin: 0 auto;
  /* padding-bottom: var(--dockHeight); */
}

.v-icon.v-icon::after {
  background: none !important;
}
/* .scaler {
  transform-origin: bottom;
  transform: scale(var(--scale));
} */
.shadowAll {
  /* background: rgba(164, 164, 164, 0.5); */
  background: rgba(221, 221, 221, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
</style>

<style>
.v-menu__content--fixed {
  position: absolute !important;
}
</style>

<style scoped>
@keyframes sun-rise {
  0% {
    opacity: 0;
    bottom: -140px;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.hidden {
  pointer-events: none;
  opacity: 0;
}

.cover2 {
  cursor: pointer;
  background: rgba(221, 221, 221, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  padding-top: 90px;
  align-items: flex-start;
  position: fixed;
  z-index: 1000;
}

/* .CreatingEventCard {
  bottom: 150px !important;
}
.CoachCard {
  bottom: 55px !important;
} */
.FullScreenCard {
  /* animation: 0.2s linear 0s alternate sun-rise; */
  position: fixed;
  z-index: 3000;
  height: 100%;
  /* max-height: 90vh; */
  justify-content: center;
  /* bottom: 12px; */
  display: flex;
  width: 100%;
  align-items: center;
  /* width: calc(100% - 8px); */
}

.FullScreenCard > div {
  max-height: 90vh;
  overflow: scroll;
  width: 360px;
}

.ConfirmCard {
  animation: 0.2s linear 0s alternate sun-rise;
  position: fixed;
  z-index: 50;
  bottom: 125px;
  left: calc(50% - 320px / 2);
}
textarea:focus,
input:focus {
  border: none !important;
  outline: none !important;
}

textarea:focus-visible {
  border: none !important;
  outline: none !important;
}

.isLogin {
  /* background: url("@/assets/LoginBackground.jpg"); */
  background-size: cover;
  /* height: 120%; */
}
</style>
