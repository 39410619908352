import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            // arena_football: {
            //     component: () =>
            //         import('@/components/icons/arena_football.vue'),
            // },
            // arena_ofp: {
            //     component: () =>
            //         import('@/components/icons/arena_ofp.vue'),
            // },
            // arena_running: {
            //     component: () =>
            //         import('@/components/icons/arena_running.vue'),
            // },
            // arena_skating: {
            //     component: () =>
            //         import('@/components/icons/arena_skating.vue'),
            // },
            // search: {
            //     component: () =>
            //         import('@/components/icons/search.vue'),
            // },
            // calendar: {
            //     component: () =>
            //         import('@/components/icons/calendar.vue'),
            // },
            // coach: {
            //     component: () =>
            //         import('@/components/icons/coach.vue'),
            // },
            // map: {
            //     component: () =>
            //         import('@/components/icons/map.vue'),
            // },
            // profile: {
            //     component: () =>
            //         import('@/components/icons/profile.vue'),
            // },
            // telegram: {
            //     component: () =>
            //         import('@/components/icons/telegram.vue'),
            // },
            player: {
                component: () =>
                    import('@/components/icons/player.vue'),
            },
            workbench: {
                component: () =>
                    import('@/components/icons/workbench.vue'),
            },
            football: {
                component: () =>
                    import('@/components/icons/sports/football.vue'),
            },
            hockey: {
                component: () =>
                    import('@/components/icons/sports/hockey.vue'),
            },
            bmx: {
                component: () =>
                    import('@/components/icons/sports/bmx.vue'),
            },
            tennis: {
                component: () =>
                    import('@/components/icons/sports/tennis.vue'),
            },
            bell_on: {
                component: () =>
                    import('@/components/icons/bell_on.vue'),
            },
            bell_off: {
                component: () =>
                    import('@/components/icons/bell_off.vue'),
            },
            chess: {
                component: () =>
                    import('@/components/icons/sports/chess.vue'),
            },
            dart: {
                component: () =>
                    import('@/components/icons/sports/dart.vue'),
            },
            skateboarding: {
                component: () =>
                    import('@/components/icons/sports/skateboarding.vue'),
            },
            home: {
                component: () =>
                    import('@/components/icons/home.vue'),
            },
            add: {
                component: () =>
                    import('@/components/icons/add.vue'),
            },
            base_ski: {
                component: () =>
                    import('@/components/icons/bases/ski.vue'),
            },
            base_skate: {
                component: () =>
                    import('@/components/icons/bases/skate.vue'),
            },
            base_sport_ground: {
                component: () =>
                    import('@/components/icons/bases/sport_ground.vue'),
            },
            base_hockey: {
                component: () =>
                    import('@/components/icons/bases/hockey.vue'),
            },
            base_football: {
                component: () =>
                    import('@/components/icons/bases/football.vue'),
            },
            base_gym: {
                component: () =>
                    import('@/components/icons/bases/gym.vue'),
            },
            base_arena: {
                component: () =>
                    import('@/components/icons/bases/arena.vue'),
            },
            base_slide: {
                component: () =>
                    import('@/components/icons/bases/slide.vue'),
            },
            account: {
                component: () =>
                    import('@/components/icons/account.vue'),
            },
            search: {
                component: () =>
                    import('@/components/icons/search.vue'),
            },
            add_coach: {
                component: () =>
                    import('@/components/icons/add_coach.vue'),
            },
            add_image: {
                component: () =>
                    import('@/components/icons/add_image.vue'),
            },
            close: {
                component: () =>
                    import('@/components/icons/close.vue'),
            },
            whistle: {
                component: () =>
                    import('@/components/icons/whistle.vue'),
            },
            check: {
                component: () =>
                    import('@/components/icons/check.vue'),
            },
            vk: {
                component: () =>
                    import('@/components/icons/vk.vue'),
            },
            clock: {
                component: () =>
                    import('@/components/icons/clock.vue'),
            },
            pencil: {
                component: () =>
                    import('@/components/icons/pencil.vue'),
            },
            human: {
                component: () =>
                    import('@/components/icons/human.vue'),
            },
            coach: {
                component: () =>
                    import('@/components/icons/coach.vue'),
            },
            admin: {
                component: () =>
                    import('@/components/icons/admin.vue'),
            },
            path: {
                component: () =>
                    import('@/components/icons/path.vue'),
            },
            delete: {
                component: () =>
                    import('@/components/icons/delete.vue'),
            },
            phone: {
                component: () =>
                    import('@/components/icons/phone.vue'),
            },
            telegram: {
                component: () =>
                    import('@/components/icons/telegram.vue'),
            },
            whatsapp: {
                component: () =>
                    import('@/components/icons/whatsapp.vue'),
            },
            // dart: {
            //     component: () =>
            //         import('@/components/icons/dart.vue'),
            // },
            // chess: {
            //     component: () =>
            //         import('@/components/icons/chess.vue'),
            // },
            // skateboarding: {
            //     component: () =>
            //         import('@/components/icons/skateboarding.vue'),
            // },

        },
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
});
