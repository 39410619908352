import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue')
  },
  {
    path: '*',
    redirect: to => {
      return { name: "Map" }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const publicPages = ['/login','/map']


  const authRequired = !publicPages.includes(to.path) && !publicPages.includes(to.path.split('/')[1])


  var user = localStorage.getItem('user')
  user = JSON.parse(user)

  //console.log(to.path)
  if (!user && authRequired) {
    next({ name: 'Login' })
  } else {
    next()
  }

})

export default router;
