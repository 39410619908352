import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import sports from './assets/sports.json'
import arenas from './assets/arenas.json'
import bases from './assets/bases.json'

// import * as Luisa from 'luisa-vue'
// import figmaDesign from './views/figma-design-system.json'
// import luisa from 'luisa-vue'
// Vue.use(luisa);
// import  'swiper/css/swiper.css'
import 'swiper/css/bundle'

import Mouseover from 'vue-mouseover';
Vue.use(Mouseover);

import VueMask from 'v-mask'
Vue.use(VueMask);

import InputFacade from 'vue-input-facade'
Vue.use(InputFacade)

import '@/components'

Vue.config.productionTip = false

Vue.prototype.sports = sports;
Vue.prototype.bases = bases;
Vue.prototype.arenas = arenas;

const mapSettings = {
  apiKey: 'c06df5ad-6b99-4f07-9b42-ba9ada084bed',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}

Vue.prototype.mapSettings = mapSettings;

import YmapPlugin from 'vue-yandex-maps'
import store from './store'
import setupInterceptors from './services/setupInterceptors';
import './registerServiceWorker'
// import './quasar'
setupInterceptors(store);
Vue.use(YmapPlugin, mapSettings)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

// async function init() {
//   await Figma.createFigmaDesignlets("yJ8muxusfL2abou9YlbfrU", "figd_vq7UwbXhRJxeZjfyPkTqPISqRcLMGF4oahnxuzFT")
 

// }

// init();
