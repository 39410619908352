import axios from "axios";
// console.log(process.env);

var instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_IP}${process.env.VUE_APP_API_PORT}/api`,
    headers: {
        "Content-Type": "application/json",
    },
});


export default instance;